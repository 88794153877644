<template>
    <b-overlay>
        <b-card>
            <b-button v-if="$checkPermission($permissions.PermissionCreateRole)" variant="primary" @click="addModal">Dodaj
                vlogo
            </b-button>

            <div>
                <b-form-input type="search" v-model="search" placeholder="Išči..." size="sm" class="float-right col-sm-2 mb-1"/>
                <b-table striped responsive="sm" bordered hover :items="filtered" :fields="fields" :filter="search"
                         :per-page="perPage" :current-page="currentPage">
                    <template slot="top-row" slot-scope="{ fields }">
                        <b-td v-for="field in fields" :key="field.key">
                            <b-form-input v-if="field.key != 'actions'" size="sm" v-model="filters[field.key]"
                                          :placeholder="field.label"/>
                        </b-td>
                    </template>
                    <template #cell(actions)="row">
                        <div class="d-flex justify-content-center">
                            <b-button v-if="$checkPermission($permissions.PermissionUpdateRole)" class="mr-1"
                                      @click="editModal(row.item.id)" variant="warning">
                                <fa icon="edit"/>
                            </b-button>
                            <b-button v-if="$checkPermission($permissions.PermissionDeleteRole)" @click="deleteRole(row.item.id)"
                                      variant="danger">
                                <fa icon="trash"/>
                            </b-button>
                        </div>
                    </template>
                </b-table>
                <b-row>
                    <b-col class="float-left">
                        <b-dropdown variant="outline" :text="'Na stran: ' + perPage" size="sm" class="btn-none">
                            <b-dropdown-item v-model="perPage" v-for="(item, key) in pageOptions" :key="key"
                                             @click="setPerPage(item)">
                                {{ item }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <b-col sm="7" md="6" class="float-right">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"/>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <b-modal title="Dodaj/uredi vlogo" v-model="modalActive" centered hide-footer no-close-on-backdrop @close="close">
            <template #default>
                <validation-observer ref="loginValidation">
                    <b-form class="auth-login-form mt-2" @submit.prevent>
                        <validation-provider name="Title" rules="required" #default="{ errors }">
                            <b-form-group label="Naslov" rules="required" label-for="role-title" invalid-feedback="Ime je obvezno"
                                          :state="errors[0] ? false:null">
                                <b-form-input id="role-title" v-model="roleObject.title" name="role-title"
                                              :state="errors[0] ? false:null"/>
                            </b-form-group>
                        </validation-provider>
                        <b-form-group label="Pravice">
                            <b-form-checkbox v-model="allSelected">Izberi vse</b-form-checkbox>
                        </b-form-group>
                        <b-form-checkbox-group v-model="roleObject.permissions">
                            <b-table small striped sticky-header :fields="fieldsPermissions" :items="filteredPermissions">
                                <template slot="top-row" slot-scope="{ fields }">
                                    <b-td v-for="field in fields" :key="field.key">
                                        <b-form-input v-if="field.key != 'id'" size="sm" v-model="filtersPermissions[field.key]"
                                                      :placeholder="field.label"/>
                                    </b-td>
                                </template>
                                <template #cell(title)='data'>
                                    {{ data.value }}
                                </template>
                                <template #cell(id)="data">
                                    <b-form-checkbox :value="data.value"/>
                                </template>
                            </b-table>
                        </b-form-checkbox-group>
                        <b-row>
                            <b-col class="text-right">
                                <b-button v-if="typeFunc == 1" type="submit" variant="primary" @click="validationForm">Dodaj</b-button>
                                <b-button v-else-if="typeFunc == 2" type="submit" variant="primary" @click="validationForm">Uredi
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
    </b-overlay>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import {required} from '@validations'
    import {togglePasswordVisibility} from '@core/mixins/ui/forms'

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                permissionsOptions: [],
                modalActive: false,
                roleObject: {id: '', permissions: [], title: ''},
                roles: [],
                allSelected: false,
                search: '',
                perPage: 10,
                pageOptions: [10, 20, 50, 100],
                currentPage: 1,
                totalRows: 1,
                fields: [
                    {key: 'title', label: 'Naslov', sortable: true, class: 'text-center'},
                    {key: 'actions', label: '', class: 'text-center'}
                ],
                fieldsPermissions: [
                    {key: 'title', label: 'Naslov', sortable: true, sortDirection: 'desc'}, {
                        key: 'id',
                        label: ''
                    }
                ],
                filters: {Id: '', Naslov: ''},
                filtersPermissions: {Naslov: ''},
                typeFunc: 0, //1-add, 2-edit,
                roleId: 0,
                required
            }
        },
        methods: {
            getRoles() {
                const thisIns = this
                this.$http.get('/api/v1/management/role')
                    .then(function(response) {
                        thisIns.roles = response.data
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! Koda: ${error.response.status}`)
                    })
            },
            getPermissions() {
                const thisIns = this
                this.$http.get('/api/v1/management/permissions')
                    .then(function(response) {
                        thisIns.permissionsOptions = response.data
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! Koda: ${error.response.status}`)
                    })
            },
            addModal() {
                this.modalActive = true
                this.typeFunc = 1
            },
            validationForm() {
                this.$refs.loginValidation.validate().then((response) => {
                    if (response && this.typeFunc === 1) this.addRole()
                    else if (response && this.typeFunc === 2) this.editRole()
                })
            },
            addRole() {
                const thisIns = this
                this.$http.put('/api/v1/management/role', thisIns.roleObject)
                    .then(function() {
                        thisIns.$printSuccess('Vloga je uspešno dodana!')
                        thisIns.modalActive = false
                        thisIns.allSelected = false
                        thisIns.roleObject = {id: '', permissions: [], title: ''}
                        thisIns.getRoles()
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! Koda: ${error.response.status}`)
                    })
            },
            editModal(roleId) {
                const thisIns = this
                this.$http.get(`/api/v1/management/role/${roleId}`)
                    .then(function(response) {
                        thisIns.roleObject = response.data
                        thisIns.modalActive = true
                        thisIns.typeFunc = 2
                        thisIns.roleId = roleId
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
            },
            editRole() {
                const thisIns = this
                this.$http.patch(`/api/v1/management/role/${thisIns.roleId}`, thisIns.roleObject)
                    .then(function() {
                        thisIns.$printSuccess('Vloga je uspešno urejena!')
                        thisIns.modalActive = false
                        thisIns.allSelected = false
                        thisIns.roleObject = {id: '', permissions: [], title: ''}
                        thisIns.getRoles()
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! Koda: ${error.response.status}`)
                    })
            },
            deleteRole(roleId) {
                const thisIns = this
                this.$bvModal.msgBoxConfirm('Prosimo potrdite, da želite izbrisati vlogo.', {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'DA',
                    cancelTitle: 'NE',
                    footerClass: 'p-2',
                    centered: true
                }).then(function(value) {
                    if (value) {
                        thisIns.$http.delete(`/api/v1/management/role/${roleId}`)
                            .then(function() {
                                thisIns.$printSuccess('Vloga je uspešno izbrisana!')
                                thisIns.getRoles()
                            }).catch(function(error) {
                                thisIns.$printWarning(`Napaka! Koda: ${error.response.status}`)
                            })
                    }
                })
            },
            setPerPage(item) {
                this.perPage = item
            },
            close() {
                this.allSelected = false
                this.roleObject = {id: '', permissions: [], title: ''}
            }
        },
        computed: {
            filtered() {
                const filtered = this.roles.filter(role => {
                    return Object.keys(this.filters).every(key => String(role[key]).toLowerCase().includes(this.filters[key].toLowerCase()))
                })
                return filtered.length > 0 ? filtered : [{Id: '', Naslov: ''}]
            },
            filteredPermissions() {
                const filtered = this.permissionsOptions.filter(permissions => {
                    return Object.keys(this.filtersPermissions).every(key => String(permissions[key]).toLowerCase().includes(this.filtersPermissions[key].toLowerCase()))
                })
                return filtered.length > 0 ? filtered : [{Naslov: ''}]
            }
        },
        created() {
            this.getRoles()
            this.getPermissions()
        },
        mounted() {
            this.totalRows = this.roles.length
        },
        watch: {
            allSelected() {
                this.roleObject.permissions = []
                if (this.allSelected) {
                    for (const i in this.permissionsOptions) {
                        this.roleObject.permissions.push(this.permissionsOptions[i].id)
                    }
                }
            }
        }
    }
</script>

<style>

</style>
